.webcam {
  width: 90%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.align-center {
  align-self: center;
}

.punchesTitle {
  margin-top: 20px;
  font-weight: bold;
  font-size: 16px;
}

button {
  margin-bottom: 20px;
}
